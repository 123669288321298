import AutoComplete from '@tarekraafat/autocomplete.js';
import { DOMEvent } from 'packs/types/DOMEvent';

type Nullable<T> = T | null

type SaleOrRent = 'sale' | 'rent';

type PlaceHolderText = 'Find rentals here' | 'Enter place name'

type SearchType = 'Property' | 'Park';

type SearchResult = {
  count: Nullable<number>;
  name: string;
  type: SearchType;
}

type Feedback = {
  query: string;
  selection: {
    key: string;
    index: number;
    match: string;
    value: SearchResult;
  };
}

const typeOfSearch = (destination: HTMLElement): SaleOrRent => {
  let type: SaleOrRent;
  if (destination.dataset.sales === 'true') {
    type = 'sale';
  } else if (destination.dataset.rentals === 'true') {
    type = 'rent';
  }
  return type;
};

const placeHolderForSearch = (type: SaleOrRent): PlaceHolderText => (
  type === 'rent' ? 'Find rentals here' : 'Enter place name'
);

const homeSearch = (): void => {
  const destination: Nullable<HTMLInputElement> = document.querySelector('#destination');
  if (destination === null) {
    return;
  }

  destination.addEventListener('keydown', (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  });

  let searchData;

  destination.addEventListener('autoComplete', (event: DOMEvent<HTMLInputElement>) => {
    const results: HTMLElement = document.querySelector('#autocomplete-list');

    results.style.display = (event.target.value.length > 2) ? 'block' : 'none';
  });

  const type: SaleOrRent = typeOfSearch(destination);
  const placeholder = destination.dataset.placeholder || placeHolderForSearch(type);

  const hiddenTypeField: HTMLInputElement = document.querySelector('#type');

  function displayCount(placeName: string, count: number, listingType: SaleOrRent): string {
    const html = `<div class="search-result-place-name">${placeName}</div>`;
    if (count) {
      return `${html} <div class="property-count">${count} for ${listingType}</div>`;
    }
    return html;
  }

  // eslint-disable-next-line no-unused-vars
  const autoCompletejs = new AutoComplete({
    data: {
      src: async (): Promise<SearchResult[]> => {
        destination.setAttribute('placeholder', 'Loading...');
        const source = await fetch(
          `/search/place_names?type=${type}`,
        );
        const data = await source.json();

        searchData = data;

        destination.setAttribute('placeholder', placeholder);
        return data;
      },
      key: ['name'],
      cache: true,
    },
    placeHolder: placeholder,
    selector: '#destination',
    threshold: 1,
    debounce: 0,
    searchEngine: 'strict',
    highlight: true,
    maxResults: 10,
    resultsList: {
      render: true,
      container: (source: HTMLElement): void => {
        source.setAttribute('id', 'autocomplete-list');
        source.setAttribute('class', 'home-UT autocomplete-list');
      },
      destination: document.querySelector('#destination'),
      position: 'afterend',
      element: 'ul',
    },
    resultItem: {
      content: (data, source: HTMLElement): void => {
        source.innerHTML = displayCount(data.match, data.value.count, type);
      },
      element: 'li',
    },
    noResults: (): void => {
      const result: HTMLElement = document.createElement('li');
      result.setAttribute('class', 'no_result');
      result.setAttribute('tabindex', '1');
      result.innerHTML = 'No results';
      document.querySelector('#autocomplete-list').appendChild(result);
    },
    onSelection: (feedback: Feedback): void => {
      const { selection } = feedback;

      destination.value = selection.value.name;

      document.querySelector<HTMLElement>('#autocomplete-list').style.display = 'none';

      hiddenTypeField.value = selection.value.type;
    },
  });

  let searchForm: HTMLFormElement = document.querySelector('#homeSearchForm:not([data-holiday-parks])');

  if (!searchForm) {
    searchForm = document.querySelector('#propertySalesSearchForm:not([data-holiday-parks])');
  }

  if (searchForm === null) return;

  searchForm.addEventListener('submit', (e) => {
    e.preventDefault();
    const searchValue = destination.value.toLowerCase();
    if (searchData) {
      const found = searchData.find((el) => el.name.toLowerCase() === searchValue);
      if (found) {
        hiddenTypeField.value = found.type;
      }
    }
    searchForm.submit();
  });

  destination.addEventListener('blur', () => {
    document.querySelector<HTMLElement>('#autocomplete-list').style.display = 'none';
  });
};

export { homeSearch, typeOfSearch, placeHolderForSearch };
