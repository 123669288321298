import optionsForFetch from '../helpers/optionsForFetch';
import type { APIObject, APICollection } from '../types/api';

interface APIManufacturerModel extends APIObject {
  attributes: {
    name: string;
  };
}

type ManufacturerResponse = Readonly<APICollection<APIManufacturerModel>>;

export default (): void => {
  const manufacuturer: HTMLSelectElement = document.querySelector('#manufacturerSelect');

  if (!manufacuturer) return;

  manufacuturer.addEventListener("change", async (): Promise<void> => {
    const manufacuturerId = manufacuturer.value;

    if (manufacuturerId === '') return;

    const json: ManufacturerResponse = await fetch(
      `/api/manufacturers/${manufacuturerId}/manufacturer_models`,
      optionsForFetch(null, 'GET'),
    ).then(
      (response) => response.json()
    ).catch((err: Error) => {
      console.log("Error: " + JSON.stringify(err));
    });

    if (!json) return;

    const manufacturerModelsSelect: HTMLSelectElement = document.querySelector('#property_manufacturer_model_id');

    Array.from(manufacturerModelsSelect.options).forEach((option) => {
      if (option.value !== '') {
        option.remove();
      }
    });

    json.data.forEach(({ id, attributes: { name } }) => {
      manufacturerModelsSelect.add(new Option(name, id as string));
    });

  })
}
