/* eslint-disable func-names, @typescript-eslint/no-unused-vars */
function propertySalesSearch() {
  const formatter = new Intl.NumberFormat('en-GB');

  const prices = Object.freeze(
    {
      min: [100000, 250000, 500000, 1000000],
      max: [100000, 500000, 1000000, 2000000, 5000000],
    },
  );

  const formatPrice = (price) => `£${formatter.format(price)}`;

  const extractPrice = (price) => {
    if (price[0] !== '£') {
      return false;
    }
    return Number(price.split('£')[1].replace(/,/g, ''));
  };

  const showDropDown = (e) => {
    e.attr('aria-expanded', true);
    e.find('ul').show(300);
  };

  const hideDropDown = (e) => {
    e.attr('aria-expanded', false);
    e.find('ul').hide(200);
  };

  const setPricesHtml = (minOrMax) => {
    let html = prices[minOrMax].map((price) => `<li class="drop-down-menu-item">${formatPrice(price)}</li>`).join('');
    html += '<li class="drop-down-menu-item">No limit</li>';
    return html;
  };

  $('#min_prices_list').html(setPricesHtml('min'));
  $('#max_prices_list').html(setPricesHtml('max'));

  $('.drop-down .price-range-pick').on('click', function () {
    const el = $(this).parent();

    hideDropDown(el.siblings());

    if (el.attr('aria-expanded') === 'true') {
      hideDropDown(el);
    } else {
      showDropDown(el);

      $(document).mouseup((e) => {
        if (el.has(e.target).length !== 0) {
          return;
        }

        $(document).unbind('mouseup');
        hideDropDown(el);
      });
    }
  });

  function onSelectPrice(event) {
    const el = $(this).parent().parent();
    const price = extractPrice($(this).html());
    const minOrMax = (el.find('#max_price').length) ? 'max' : 'min';

    hideDropDown(el);

    el.find('.price-range-pick').html($(this).html());
    el.find('.price-range-pick').css('cssText', 'color: black !important;');
    if (minOrMax === 'max') {
      $('#max_price').val(price);
    } else {
      $('#min_price').val(price);
    }
  }

  $('.drop-down-menu .drop-down-menu-item').on('click', onSelectPrice);
}

export default propertySalesSearch;
