// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs';
import * as ActiveStorage from '@rails/activestorage';
import 'channels';

import i18nDescriptions from './descriptions/i18n-descriptions';
import SearchByFields from './search/search-by-fields';
import documentReady from './helpers/documentReady';
import infoHover from './forms/info-hover';
import getManufacturerModels from './parks/getManufacturerModels';
import enquire from './parks/enquire';

Rails.start();
ActiveStorage.start();

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require('trix');
require('@rails/actiontext');
require('application');
require('./cookie-consent.js');

documentReady(() => {
  i18nDescriptions();
  SearchByFields();
  infoHover();
  enquire();
  getManufacturerModels();
});
