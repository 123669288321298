/**
 * Removes an attribute if the `attributeValue` is null.
 * Will set `attributeName` to `attributeValue` otherwise.
 * @param {HTMLElement} element Element to operate on
 * @param {string} attributeName Name of the attribute
 * @param {string} attributeValue The value you want to set on the attribute
 */
export default function removeOrSetAttribute(
  element: HTMLElement, attributeName: string, attributeValue: string,
): void {
  if (attributeValue == null) {
    element.removeAttribute(attributeName);
  } else {
    element.setAttribute(attributeName, attributeValue);
  }
}
