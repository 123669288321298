import type { HTTPMethod } from '../types/HTTPMethod';
import token from './csrfToken';

export default function optionsForFetch(body: BodyInit, method: HTTPMethod): RequestInit {
  return ({
    method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-Token': token(),
    },
    body,
    credentials: 'same-origin',
  });
}
