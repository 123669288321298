function calendarControls() {
  $('#later').click(() => {
    if ($('.calendar-month:visible').last().next('.calendar-month').length) {
      const a = $('.calendar-month:visible').last().next('.calendar-month');
      const b = a.next();
      const c = b.next();
      $('.calendar-month:visible').hide();
      a.show();
      b.show();
      c.show();
    }
    return false;
  });

  $('#earlier').click(() => {
    if ($('.calendar-month:visible').first().prev('.calendar-month').length) {
      const a = $('.calendar-month:visible').first().prev('.calendar-month');
      const b = a.prev();
      const c = b.prev();
      $('.calendar-month:visible').hide();
      a.show();
      b.show();
      c.show();
    }
    return false;
  });
}

export default calendarControls;
