import documentReady from '../packs/helpers/documentReady';
import countrySelect from './country-select.ts';
import { homeSearch } from './home-search';
import propertySalesSearch from './property-sales-search';
import mainMenu from './main-menu';
import additionalMenu from './additional-menu';
import pageLoad from './general';
import calendarControls from './calendar';
import initRecaptcha from './invisible-recaptcha';

documentReady(() => {
  initRecaptcha();
  homeSearch();
  propertySalesSearch();
  mainMenu();
  additionalMenu();
  pageLoad();
  calendarControls();
  countrySelect();
});
