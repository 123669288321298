export default (): void => {
  const populateCountrySelect = (select: HTMLSelectElement, blank: string): void => {
    // Ignore if already populated or fetching has started.
    if (select.childNodes.length > 1 || select.dataset.fetchStarted === 'true') {
      return;
    }
    const blankOption = `<option value="">${blank}</option>`;
    fetch('/countries/options_for_select')
      .then((response) => response.text())
      .then((html) => {
        select.innerHTML = blankOption + html; // eslint-disable-line no-param-reassign
      });
  };

  const forms = document.querySelectorAll('form.has-country-select') as NodeListOf<HTMLFormElement>;
  forms.forEach((form) => {
    const select = document.getElementById(form.dataset.countrySelect) as HTMLSelectElement;
    const blank = form.dataset.blankCountry;
    form.addEventListener('focusin', () => {
      populateCountrySelect(select, blank);
      select.dataset.fetchStarted = 'true';
    });
  });
};
