// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any;

const initRecaptcha = (): void => {
  const submitRecaptchaForm = (token: string): void => {
    const form: HTMLFormElement = document.querySelector('form[action="/subscriptions"]');
    form.submit();
  };

  window.submitRecaptchaForm = submitRecaptchaForm;
};

export default initRecaptcha;
