import documentReady from './helpers/documentReady';

documentReady(() => {
  const cookieContainer = document.querySelector('.cookie-container');
  if (cookieContainer === null) {
    return;
  }

  if (!document.cookie.includes('allow_cookies')) {
    cookieContainer.style.display = 'flex';
  } else {
    cookieContainer.remove();
  }

  const acceptButton = document.querySelector('.cookie-buttonAccept');
  const declineButton = document.querySelector('.cookie-buttonDecline');

  if (acceptButton) {
    acceptButton.addEventListener('click', () => {
      document.cookie = 'allow_cookies=true; path=/;';
      cookieContainer.remove();
    });
  }

  if (declineButton) {
    declineButton.addEventListener('click', () => {
      document.cookie = 'allow_cookies=false; path=/;';
      cookieContainer.remove();
    });
  }
});
