import type { DOMEvent } from '../types/DOMEvent';

export default (): void => {
  const hovers: HTMLDivElement[] = Array.from(document.querySelectorAll('[data-hover-info]'));

  function removeAllTooltips(): void {
    const toolTips: HTMLDivElement[] = Array.from(document.querySelectorAll('.hover-temp'));

    toolTips.forEach((el) => {
      el.remove();
    });
  }

  function createTooltip(hoverable: HTMLElement): HTMLDivElement {
    removeAllTooltips();

    const toolTip = document.createElement('div');
    toolTip.classList.add('hover-temp');
    toolTip.textContent = hoverable.dataset.hoverInfo;
    hoverable.insertAdjacentElement('afterend', toolTip);

    return toolTip;
  }

  if (hovers.length) {
    hovers.forEach((hover) => {
      const body = document.querySelector('body');

      hover.addEventListener('click', (event) => {
        event.preventDefault();
        if (document.querySelector('.hover-temp')) {
          removeAllTooltips();
        } else {
          createTooltip(hover);
        }
      });

      body.addEventListener('click', (event: DOMEvent<HTMLElement>) => {
        if (!event.target.dataset.hoverInfo) {
          removeAllTooltips();
        }
      });
    });
  }
};
