export default function additionalMenu() {
  window.addEventListener('load', () => {
    const mobileNavigation = document.getElementById('navMobilePopup');

    const closeNavigation = () => {
      if (mobileNavigation.style.display !== 'none') {
        mobileNavigation.style.display = 'none';
      }
    };

    const openNavigation = () => {
      if (mobileNavigation.style.display !== 'block') {
        mobileNavigation.style.display = 'block';
      }
    };

    const toggleNavigation = () => {
      if (mobileNavigation.style.display === 'none') {
        openNavigation();
      } else {
        closeNavigation();
      }
    };

    document.querySelectorAll('.additionalMenu-toggle').forEach((t) => {
      t.addEventListener('click', toggleNavigation);
    });

    const checkWindowSize = () => {
      if (window.innerWidth < 768) {
        closeNavigation();
      } else {
        openNavigation();
      }
    };

    window.addEventListener('resize', checkWindowSize);
    checkWindowSize();
  });
}
