import isInput from '../helpers/isInput';
import removeOrSetAttribute from '../helpers/removeOrSetAttribute';

/**
 * Possible search by options supported for MyHolidayParks
 */
type SearchByOption = 'availability' | 'location' | 'drive_distance' | 'park_name';

/**
 * Casts attribute `value` to given type `T`
 */
type ValueOf<T> = {
  value: T;
}

type SearchBySelect = HTMLSelectElement & ValueOf<SearchByOption>;

function findSearchByFields(fields: string): HTMLDivElement {
  return document.querySelector(`[data-search="${fields}"]`);
}

function changeInputAttributesRecursively(
  { children }: HTMLElement, attributeName: string, attributeValue: string,
): void {
  Array.from(children).forEach((element: HTMLElement) => {
    if (isInput(element)) {
      removeOrSetAttribute(element, attributeName, attributeValue);
    }
    changeInputAttributesRecursively(element, attributeName, attributeValue);
  });
}

function hideAllFields(): void {
  const fields: HTMLDivElement[] = Array.from(document.querySelectorAll('[data-search]'));

  fields.forEach((field): void => {
    // eslint-disable-next-line no-param-reassign
    field.style.display = 'none';
    changeInputAttributesRecursively(field, 'disabled', 'true');
  });
}

const setIconActiveStyle = (value: string): void => {
  document.querySelectorAll('.homeSearch-optionActive').forEach((o) => { o.classList.remove('homeSearch-optionActive'); });
  document.querySelector(`[data-search-by="${value}"]`).classList.add('homeSearch-optionActive');
};

function setCurrentFields(value: string): void {
  const field = findSearchByFields(value);

  setIconActiveStyle(value);
  hideAllFields();
  field.style.display = 'flex';
  const searchButton: HTMLElement = document.querySelector("[data-container='button']");
  const searchBy: HTMLElement = document.querySelector("[data-container='search-by']");
  searchButton.remove();
  searchBy.remove();
  field.prepend(searchBy);
  const searchBySelect: HTMLSelectElement = searchBy.querySelector('#search_by');
  searchBySelect.value = value;
  field.appendChild(searchButton);
  changeInputAttributesRecursively(field, 'disabled', null);
}

function onLocationSuccess({ coords: { latitude, longitude } }: GeolocationPosition): void {
  const userLatitudeInput: HTMLInputElement = document.querySelector('#latitude');
  const userLongitudeInput: HTMLInputElement = document.querySelector('#longitude');

  userLatitudeInput.value = String(latitude);
  userLongitudeInput.value = String(longitude);
}

function onLocationError({ code, message }: GeolocationPositionError): void {
  document.querySelector<HTMLInputElement>('#parksSearch-formSubmit').disabled = false;
}

function retrieveUserLocation(form: HTMLFormElement): void {
  navigator.geolocation.getCurrentPosition(
    (position) => {
      onLocationSuccess(position);
      form.submit();
    },
    (error) => {
      onLocationError(error);
      alert('We need access to your current location to search by drive distance.');
    },
  );
}

export default (): void => {
  let regions: HTMLCollection;
  const searchByField: SearchBySelect = document.querySelector('#search_by');
  if (searchByField === null) { return; }

  const form: HTMLFormElement = document.querySelector('#homeSearchForm');

  setCurrentFields(searchByField.value);

  function populateRegions(countrySelect, regionSelect): void {
    const countryName = countrySelect.querySelector(`[value="${countrySelect.value}"]`).innerHTML;
    const filteredRegions = Object.entries(regions).filter(([key, val]: [string, HTMLElement]) => val.dataset.country === countryName);
    regionSelect.innerHTML = '<option value="">Any</option>';
    let sortedRegions = filteredRegions.sort((a, b) => { return a[1].innerHTML < b[1].innerHTML ? -1 : 1 });
    sortedRegions.forEach(([key, val]: [any, HTMLOptionElement]) => {
      let select = val.getAttribute('selected') === 'selected'
      regionSelect.innerHTML += `<option value="${val.value}" ${select && "selected"}>${val.innerHTML}</option>`;
    });
  }

  function setCountryRegionDropdown(): void {
    const countrySelect: HTMLElement = document.getElementById(`country_${searchByField.value}`);
    const regionSelect: HTMLElement = document.getElementById(`region_${searchByField.value}`);
    if (!countrySelect && !regionSelect) { return }
    if (!regions) { regions = Object.assign({}, regionSelect.children); }
    regionSelect.innerHTML = '<option value="">Any</option>';
    populateRegions(countrySelect, regionSelect);

    countrySelect.addEventListener('change', () => {
      populateRegions(countrySelect, regionSelect);
    });
  }

  searchByField.addEventListener('change', (): void => {
    setCurrentFields(searchByField.value);
    setCountryRegionDropdown();
  });

  document.querySelectorAll('.homeSearch-optionWrapper').forEach((o: HTMLElement) => {
    o.addEventListener('click', (): void => {
      setCurrentFields(o.dataset.searchBy);
      setCountryRegionDropdown();
    });
  });

  setCountryRegionDropdown();

  form.addEventListener('submit', (event) => {
    event.preventDefault();

    if (searchByField.value === 'drive_distance') {
      retrieveUserLocation(form);
    } else {
      form.submit();
    }
  });
};
