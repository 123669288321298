/* eslint-disable no-control-regex */
function cycleHeaderImages() {
  $('#header-images').cycle({
    fx: 'fade',
    speed: 1500,
    timeout: 3000,
  });
}

function galleryZoom() {
  $('#gallery_thumbnails a').featherlight({ targetAttr: 'href' });
}

function searchFixOnScroll() {
  $(window).scroll(() => {
    if ($(this).scrollTop() > 300) {
      $('.search-fields').addClass('search-fixed');
    } else {
      $('.search-fields').removeClass('search-fixed');
    }
  });
}

function pageLoad() {
  cycleHeaderImages();
  galleryZoom();
  searchFixOnScroll();
}

window.hideLinksAndSearch = () => {
  $('body').addClass('hide-links-and-search');
};

window.isValidEmailAddress = (emailAddress) => {
  // eslint-disable-next-line no-useless-escape
  const pattern = new RegExp(/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i);
  pattern.test(emailAddress);
};

export default pageLoad;
