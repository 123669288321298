/**
 * Tests whether an element is either an `input` or a `select`
 * @param {HTMLElement} element The element in question
 * @returns {boolean}
 */
export default function isInput(element: HTMLElement): boolean {
  if (element.tagName === 'INPUT' || element.tagName === 'SELECT') {
    return true;
  }
  return false;
}
