export default (): void => {
  const enquiryBtn: HTMLButtonElement = document.querySelector('#parkEnquiryButton');
  const enquiryModal: HTMLDivElement = document.querySelector('#parkEnquiryModal');
  const enquiryShadow: HTMLDivElement = document.querySelector('.parkEnquiryModal-shadow');
  const enquiryClose: HTMLButtonElement = document.querySelector('.parkEnquiryModal-close');

  if (enquiryBtn && enquiryModal && enquiryShadow && enquiryClose) {
    enquiryBtn.addEventListener('click', () => {
      enquiryModal.style.display = 'block';
    });

    [enquiryShadow, enquiryClose].forEach((element) => {
      element.addEventListener('click', () => {
        enquiryModal.style.display = 'none';
      });
    });
  }
};
