/* eslint-disable no-undef */

'use-strict';

export default function mainMenu() {
  function showDropDown(e) {
    e.attr('aria-expanded', true);

    if (e.children('ul').length === 0) {
      e.children('li').show(300);
      return;
    }
    e.children('ul').show(300);
  }

  function hideDropDown(e) {
    e.attr('aria-expanded', false);

    if (e.children('ul').length === 0) {
      e.children('li').hide(200);
      return;
    }
    e.children('ul').hide(200);
  }

  function setDropDown(e) {
    e.stopPropagation();
    // --- close opened dropdown ----
    hideDropDown($(this).siblings());

    // --- close dropdown on double click ---
    if ($(this).attr('aria-expanded') === 'true') {
      hideDropDown($(this));
    } else {
      // --- close dropdown on click outside ---
      const elm = $(this);

      $(document).on('mouseup', (event) => {
        if (elm.has(event.target).length !== 0) {
          return;
        }

        $(document).off('mouseup');
        hideDropDown(elm);
      });

      showDropDown($(this));
    }
  }

  $('.mainMenu-borderBottom li.main-menu-item').on('click', setDropDown);

  $('.mainMenu-borderBottom ul.main-menu-item-links > li').on('click', () => {
    $(this).parent().hide(200);
  });
}
