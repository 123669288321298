export default (): void => {
  const userLanguage: string = navigator.language.split('-')[0];

  const descriptionDe: HTMLElement = document.querySelector('.description-de');
  const descriptionFr: HTMLElement = document.querySelector('.description-fr');
  const descriptionEn: HTMLElement = document.querySelector('.description-en');

  const languageElementMapping = {
    en: descriptionEn,
    fr: descriptionFr,
    de: descriptionDe,
  };

  const selectedLanguage: HTMLElement | undefined | null = languageElementMapping[userLanguage];

  if (selectedLanguage) {
    descriptionEn.style.display = 'none';

    languageElementMapping[userLanguage].style.display = 'block';
  }
};
